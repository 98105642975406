import React from "react";
import Layout from "../component/layout";
import HomeImage from "../images/application-modernization-home-image.png";
import WebsLogoSwiper from "../component/websLogoSwiper";
import ItExpandTeamComponent from "../component/Services/IT/ItExpandTeamComponent";
import Icon from "../images/card-icon-10.png";
import TechStackComponent from "../component/Services/techStackComponent";
import CustomDevelopmentVideoComponent from "../component/Services/CustomDevelopment/customDevelopmentVideoComponent";
import BookCallServiceComponent from "../component/bookCallServiceComponent";
import FooterComponent from "../component/footerComponent";
import ServicesHomeComponentV2 from "../component/servicesHomeComponentV2";
import AccordionComponent from "../component/accordionComponent";
import CardsComponent from "../component/CardsComponent";
import BorderCards from "../component/BorderCards";
import { graphql } from "gatsby";
const ServiceApplicationModernization = (props) => {
  const {
    data: {
      wpPage: {
        companyMissionSection: {
          companyMissionTitle,
          companyMissionDescription,
          companyMissionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: homeImg },
            },
          },
        },
        blogAlbaniaArticle: {
          blogAlbaniaArticleTitle,
          blogAlbaniaArticleAuthor,
          blogAlbaniaArticleText,
          blogAlbaniaArticleData,
          blogAlbaniaArticleDescription,
        },
        careerPositionSection: {
          careerPositionTitle,
          careerPositionDescription,
          careerPositionFornend,
          careerPositionBackend,
          careerPositionDevops,
          careerPositionDesigner,
          careerPositionQa,
          careerPositionProjectManagement,
          careerPositionProjectManager,
          careerPositionSupport,
          careerPositionCostumerSupport,
          careerPositionContentManager,
          careerPositionSocialMediaMarketing,
          careerPositionMarketingManager,
          careerPositionTechnicalWriter,
          careerPositionText,
          careerPositionLocation,
        },
        aboutUsHomeSection: {
          aboutUsHomeTitle,
          aboutUsHomeDescription,
          aboutUsHomeSolutionTitle,
          aboutUsHomeSolutionTextOne,
          aboutUsHomeSolutionTextTwo,
          aboutUsHomeSolutionTextThree,
        },
        customDevelopmentCards: {
          customDevelopmentTitle,
          customDevelopmentCardTitle1,
          customDevelopmentCardDescription1,
          customDevelopmentCardTitle2,
          customDevelopmentCardDescription2,
          customDevelopmentCardTitle3,
          customDevelopmentCardDescription3,
          customDevelopmentCardTitle4,
          customDevelopmentCardDescription4,
          customDevelopmentCardTitle5,
          customDevelopmentCardDescription5,
          customDevelopmentCardTitle6,
          customDevelopmentCardDescription6,
          customDevelopmentCardTitle7,
          customDevelopmentCardDescription7,
          customDevelopmentCardTitle8,
          customDevelopmentCardDescription8,
          customDevelopmentCardTitle9,
          customDevelopmentCardDescription9,
        },

        processSection: {
          processSubtitle,
          processDescription,
          processAnalysisTitle,
          processAnalysisDescription,
          processProductTitle,
          processProductDescription,
          processDesigningTitle,
          processDesigningDescription,
          processAgileTitle,
          processAgileDescription,
        },
      },
    },
  } = props;
  const seo = {
    title: "Application Modernization Services & Solutions  |  Motomtech",
    metaDesc:
      "With Motomtech’s Application Modernization Services, we take your legacy solution to the future. Our disruption free model keeps your business running while we update your core offering.",
  };
  const cards = [
    {
      icon: Icon,
      title: customDevelopmentCardTitle1,
      description: customDevelopmentCardDescription1,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle2,
      description: customDevelopmentCardDescription2,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle3,
      description: customDevelopmentCardDescription3,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle4,
      description: customDevelopmentCardDescription4,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle5,
      description: customDevelopmentCardDescription5,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle6,
      description: customDevelopmentCardDescription6,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle7,
      description: customDevelopmentCardDescription7,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle8,
      description: customDevelopmentCardDescription8,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle9,
      description: customDevelopmentCardDescription9,
    },
  ];
  const questions1 = [
    {
      title: processAnalysisTitle,
      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processAnalysisDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 1,
    },
    {
      title: processProductTitle,
      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processProductDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 2,
    },
  ];

  const questions2 = [
    {
      title: processDesigningTitle,
      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processDesigningDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 3,
    },
    {
      title: processAgileTitle,
      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processAgileDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 4,
    },
  ];
  return (
    <Layout seo={seo} title={seo.title}>
      <ServicesHomeComponentV2
        homeTitle={companyMissionTitle}
        homeDescription={companyMissionDescription}
        homeImage={homeImg}
        homeButton={"Request our services"}
      />
      <WebsLogoSwiper />
      <ItExpandTeamComponent
        title={blogAlbaniaArticleTitle}
        description1={blogAlbaniaArticleAuthor}
        description2={blogAlbaniaArticleText}
        description3={blogAlbaniaArticleData}
        description4={blogAlbaniaArticleDescription}
        showText={true}
      />
      <BorderCards
        borderCardsTitle={careerPositionTitle}
        borderCardsText1={careerPositionDescription}
        borderCardsText2={careerPositionFornend}
        borderCardsText3={careerPositionBackend}
        borderCardsText4={careerPositionDevops}
        borderCardsText5={careerPositionDesigner}
        borderCardsText6={careerPositionQa}
        borderCardsText7={careerPositionProjectManagement}
        borderCardsText8={careerPositionProjectManager}
        borderCardsText9={careerPositionSupport}
        borderCardsText10={careerPositionCostumerSupport}
        borderCardsText11={careerPositionContentManager}
        borderCardsText12={careerPositionSocialMediaMarketing}
        borderCardsText13={careerPositionMarketingManager}
        borderCardsText14={careerPositionTechnicalWriter}
        borderCardsText15={careerPositionText}
        borderCardsText16={careerPositionLocation}
      />
      <ItExpandTeamComponent
        title={aboutUsHomeTitle}
        description1={aboutUsHomeDescription}
        description2={aboutUsHomeSolutionTitle}
        description3={aboutUsHomeSolutionTextOne}
        description4={aboutUsHomeSolutionTextTwo}
        description5={aboutUsHomeSolutionTextThree}
      />
      <div style={{ marginBottom: "-135px" }}>
        <CardsComponent
          cards={cards}
          title={customDevelopmentTitle}
          showCards={true}
        />
      </div>

      <TechStackComponent />
      <CustomDevelopmentVideoComponent />
      <AccordionComponent
        title={processSubtitle}
        subtitle={processDescription}
        arr1={questions1}
        arr2={questions2}
      />
      <div
        style={{
          background:
            "linear-gradient(108.34deg, #09062D -2.63%, #36498C 100%)",
        }}
      >
        <BookCallServiceComponent
          bookCallServiceTitle={"Modernize your Software with Motomtech"}
          bookCallServiceDescription={"Stop wasting time."}
          isFirstService={true}
          isSecondService={false}
        />
      </div>
      <FooterComponent />
    </Layout>
  );
};

export default ServiceApplicationModernization;

export const query = graphql`
  {
    wpPage(slug: { eq: "service-application-modernization" }) {
      companyMissionSection {
        companyMissionTitle
        companyMissionDescription
        companyMissionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      blogAlbaniaArticle {
        blogAlbaniaArticleTitle
        blogAlbaniaArticleAuthor
        blogAlbaniaArticleText
        blogAlbaniaArticleData
        blogAlbaniaArticleDescription
      }
      careerPositionSection {
        careerPositionTitle
        careerPositionDescription
        careerPositionFornend
        careerPositionBackend
        careerPositionDevops
        careerPositionDesigner
        careerPositionQa
        careerPositionProjectManagement
        careerPositionProjectManager
        careerPositionSupport
        careerPositionCostumerSupport
        careerPositionContentManager
        careerPositionSocialMediaMarketing
        careerPositionMarketingManager
        careerPositionTechnicalWriter
        careerPositionText
        careerPositionLocation
      }
      aboutUsHomeSection {
        aboutUsHomeTitle
        aboutUsHomeDescription
        aboutUsHomeSolutionTitle
        aboutUsHomeSolutionTextOne
        aboutUsHomeSolutionTextTwo
        aboutUsHomeSolutionTextThree
      }
      customDevelopmentCards {
        customDevelopmentTitle
        customDevelopmentCardTitle1
        customDevelopmentCardDescription1
        customDevelopmentCardTitle2
        customDevelopmentCardDescription2
        customDevelopmentCardTitle3
        customDevelopmentCardDescription3
        customDevelopmentCardTitle4
        customDevelopmentCardDescription4
        customDevelopmentCardTitle5
        customDevelopmentCardDescription5
        customDevelopmentCardTitle6
        customDevelopmentCardDescription6
        customDevelopmentCardTitle7
        customDevelopmentCardDescription7
        customDevelopmentCardTitle8
        customDevelopmentCardDescription8
        customDevelopmentCardTitle9
        customDevelopmentCardDescription9
      }

      processSection {
        processSubtitle
        processDescription
        processAnalysisTitle
        processAnalysisDescription
        processProductTitle
        processProductDescription
        processDesigningTitle
        processDesigningDescription
        processAgileTitle
        processAgileDescription
      }
    }
  }
`;
